@import '../.common-styles/master.scss';

.restaurant-status-pill {
  width: 120px;
  height: 25px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    background-color: $primary;
  }

  &.reviewing {
    background-color: $secondary-dark2;
  }

  &.deactivated {
    background-color: $dark3;
  }

  &.blacklisted {
    background-color: $dark;
    border-color: $dark3;
  }

  &.unknown {
    background-color: $dark2;
    border-color: $dark3;
  }
}
