@import '../.common-styles/master.scss';

.container {
  background-color: $dark4;
  padding: 10px 30px;
  display: flex;
  border-bottom: $dashed-border;
  width: 100%;

  &.clickable {
    cursor: pointer;
  }
}

.top-icon-container {
  display: flex;
  padding-bottom: 6px;
}

.top-icon {
  padding-right: 8px;

  &:last-child {
    padding-right: 0;
  }
}

.sub-container {
  overflow: hidden;
}

.detail-table {
  table-layout: fixed;
  display: table;

  .row {
    display: table-row;
  }

  .cell {
    display: table-cell;
    min-width: 120px;
    padding-top: 6px;
  }
}

.end-icon {
  position: relative;
  margin: auto 0 auto auto;
}

.badge {
  background-color: $alert;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding: 5px;
  border-radius: 25px;
  position: absolute;
  right: 20px;
}
