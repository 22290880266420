@import '../.common-styles/master.scss';

.alert-resolve-popup {
  width: 320px;
  padding: 20px;
}

.title {
  padding-bottom: 20px;
}

.text-area {
  padding-bottom: 20px;
}

.button-container {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
}

.button {
  padding-bottom: 10px;
  &:last-child {
    padding-bottom: 0;
  }
}
