@import '../.common-styles/master.scss';

.distribution-alert-summary {
  width: 100%;
  background-color: $dark4;
}

.detail-row {
  border-bottom: $dashed-border;
  padding: 10px 20px;

  &:last-child {
    border-bottom: none;
  }

  &.centered-row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.flex {
  display: flex;
}

.left-flex-item {
  padding-right: 75px;
}
