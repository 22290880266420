.logo {
  background-size: contain;
  background-repeat: no-repeat;
}

.clickable {
  cursor: pointer;

  &:hover {
    filter: brightness(120%);
  }
}

.fab-board {
  height: 50px;
  width: 50px;
  background-image: url(\./assets/fab-board.svg);
}

.fab-cancel {
  height: 50px;
  width: 50px;
  background-image: url(\./assets/fab-cancel.svg);
}

.fab-config {
  height: 50px;
  width: 50px;
  background-image: url(\./assets/fab-config.svg);
}

.fab-edit {
  height: 50px;
  width: 50px;
  background-image: url(\./assets/fab-edit.svg);
}

.fab-info {
  height: 50px;
  width: 50px;
  background-image: url(\./assets/fab-info.svg);
}

.fab-map {
  height: 50px;
  width: 50px;
  background-image: url(\./assets/fab-map.svg);
}

.fab-ok {
  height: 50px;
  width: 50px;
  background-image: url(\./assets/fab-ok.svg);
}

.fab-page-top {
  height: 50px;
  width: 50px;
  background-image: url(\./assets/fab-page-top.svg);
}

.fab-request {
  height: 50px;
  width: 50px;
  background-image: url(\./assets/fab-request.svg);
}

.fab-local-time-2 {
  height: 50px;
  width: 50px;
  background-image: url(\./assets/fab-local-time-2.svg);
}

.fab-local-time {
  height: 50px;
  width: 50px;
  background-image: url(\./assets/fab-local-time.svg);
}

.fab-chat {
  height: 50px;
  width: 50px;
  background-image: url(\./assets/fab-chat.svg);
}

.no-image {
  height: 200px;
  width: 200px;
  background-image: url(\./assets/no-image.svg);
}

.logo-white {
  height: 52px;
  width: 173px;
  background-image: url(\./assets/logo-white.svg);
}

.logo-gold {
  height: 52px;
  width: 173px;
  background-image: url(\./assets/logo-gold.svg);
}
