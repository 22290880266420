@import '../.common-styles/master.scss';

@font-face {
  font-family: sbc-icon;
  src: url(\./assets/sbc-icons.woff?3xlmqe) format('woff');
  font-weight: normal;
  font-style: normal;
}

.icon {
  font-family: sbc-icon, sans-serif;
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.circular {
    border-radius: 50%;
    padding: 5px;

    &.size {
      &-x-large,
      &-xx-large {
        padding: 10px;
      }
    }

    &-bg-color-dark3 {
      background-color: $dark3;
    }

    &-bg-color-dark2 {
      background-color: $dark2;
    }

    &-bg-color-secondary-dark1 {
      background-color: $secondary-dark1;
    }
  }

  &.clickable {
    cursor: pointer;
    &:hover {
      filter: brightness(120%);
    }
  }
}

.size {
  &-xx-small {
    font-size: 10px;
  }

  &-x-small {
    font-size: 12px;
  }

  &-small {
    font-size: 16px;
  }

  &-medium {
    font-size: 24px;
  }

  &-large {
    font-size: 32px;
    padding: 10px;
  }

  &-x-large {
    font-size: 40px;
    padding: 10px;
  }

  &-xx-large {
    font-size: 48px;
    padding: 10px;
  }

  &-xxx-large {
    font-size: 60px;
    padding: 10px;
  }
}

.display {
  &-block {
    display: block;
  }

  &-inline {
    display: inline;
  }
}

.color {
  &-default {
    color: $main-font-color;
  }

  &-black {
    color: $black;
  }

  &-primary {
    color: $primary;
  }

  &-dark {
    color: $dark;
  }

  &-dark0 {
    color: $dark0;
  }

  &-dark4 {
    color: $dark4;
  }

  &-secondary-dark1 {
    color: $secondary-dark1;
  }

  &-secondary-dark2 {
    color: $secondary-dark2;
  }

  &-white {
    color: $white;
  }
}

.notice {
  &::before {
    content: '\e900';
  }
}

.distribution {
  &::before {
    content: '\e901';
  }
}

.monitor {
  &::before {
    content: '\e902';
  }
}

.orderscan {
  &::before {
    content: '\e903';
  }
}

.history {
  &::before {
    content: '\e904';
  }
}

.task {
  &::before {
    content: '\e905';
  }
}

.chat {
  &::before {
    content: '\e906';
  }
}

.dashboard {
  &::before {
    content: '\e907';
  }
}

.customize {
  &::before {
    content: '\e908';
  }
}

.report {
  &::before {
    content: '\e909';
  }
}

.brewery {
  &::before {
    content: '\e90a';
  }
}

.brand {
  &::before {
    content: '\e90b';
  }
}

.distributor {
  &::before {
    content: '\e90c';
  }
}

.restaurant {
  &::before {
    content: '\e90d';
  }
}

.order {
  &::before {
    content: '\e90e';
  }
}

.camera {
  &::before {
    content: '\e90f';
  }
}

.menu {
  &::before {
    content: '\e910';
  }
}

.close {
  &::before {
    content: '\e911';
  }
}

.reload {
  &::before {
    content: '\e912';
  }
}

.bottle {
  &::before {
    content: '\e913';
  }
}

.edit {
  &::before {
    content: '\e914';
  }
}

.plus {
  &::before {
    content: '\e915';
  }
}

.top {
  &::before {
    content: '\e916';
  }
}

.left {
  &::before {
    content: '\e917';
  }
}

.right {
  &::before {
    content: '\e918';
  }
}

.left2 {
  &::before {
    content: '\e919';
  }
}

.right2 {
  &::before {
    content: '\e91a';
  }
}

.search {
  &::before {
    content: '\e91b';
  }
}

.filter {
  &::before {
    content: '\e91c';
  }
}

.map {
  &::before {
    content: '\e91d';
  }
}

.consumer {
  &::before {
    content: '\e91e';
  }
}

.check {
  &::before {
    content: '\e91f';
  }
}

.info {
  &::before {
    content: '\e920';
  }
}

.question {
  &::before {
    content: '\e921';
  }
}

.instock {
  &::before {
    content: '\e922';
  }
}

.shipment {
  &::before {
    content: '\e923';
  }
}

.list {
  &::before {
    content: '\e924';
  }
}

.system {
  &::before {
    content: '\e925';
  }
}

.r-inspection {
  &::before {
    content: '\e926';
  }
}

.i-permission {
  &::before {
    content: '\e927';
  }
}

.i-start {
  &::before {
    content: '\e928';
  }
}

.i-history {
  &::before {
    content: '\e929';
  }
}

.down {
  &::before {
    content: '\e92a';
  }
}

.calender {
  &::before {
    content: '\e92b';
  }
}

.language {
  &::before {
    content: '\e92c';
  }
}

.d-manage {
  &::before {
    content: '\e92d';
  }
}

.order2 {
  &::before {
    content: '\e92e';
  }
}

.r-manage {
  &::before {
    content: '\e92f';
  }
}

.marketing {
  &::before {
    content: '\e930';
  }
}

.master {
  &::before {
    content: '\e931';
  }
}

.logo {
  &::before {
    content: '\e932';
  }
}

.clock {
  &::before {
    content: '\e933';
  }
}

.sorting {
  &::before {
    content: '\e934';
  }
}

.charts {
  &::before {
    content: '\e935';
  }
}
