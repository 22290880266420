@import '../.common-styles/master.scss';

.content {
  max-width: 350px;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;

  .item {
    padding-bottom: 10px;

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.title {
  padding: 20px 0;
}

.separator {
  padding: 0 5px;
}

.buttons {
  padding: 40px 0 20px;

  .button {
    padding-bottom: 10px;

    &:last-child {
      padding-bottom: 0;
    }
  }
}
