@import '../.common-styles/master.scss';

.container {
  display: inline-block;
  position: relative;

  &.full {
    width: 100%;
  }

  &.fit {
    width: auto;
  }
}

.select-box {
  font-family: $main-font-family;
  appearance: none;
  -webkit-appearance: none;
  height: 44px;
  line-height: 44px;
  margin-right: 2px;
  padding-left: 11px;
  padding-right: 40px;
  border-radius: 25px;
  font-size: 14px;
  border: solid 1px;
  box-sizing: border-box;
  outline: none;

  &.primary {
    background-color: $white;
    color: $secondary-dark2;
    border-color: $dark;
  }

  &.secondary {
    background-color: $dark3;
    border-color: $dark4;
    color: $white;
  }

  &.has-prefix-icon {
    padding-left: 40px;
  }

  &.full {
    width: 100%;
  }

  &.fit {
    width: auto;
  }
}

.prefix-icon {
  position: absolute;
  top: 10px;
  left: 10px;
}

.suffix-icon {
  position: absolute;
  top: 14px;
  right: 14px;
}
