@import '../.common-styles/master.scss';

.card-button {
  background-color: $dark4;
  display: flex;
  border-radius: 10px;
  cursor: pointer;
  justify-content: space-between;

  &:hover {
    filter: brightness(120%);
  }

  .left-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
  }

  // Sreenath29Sept2021- Bug raised by customer/Align badge to top right corner.
  .right-content {
    display: flex;
    align-items: flex-start;
    vertical-align: top;
  }

  &.full {
    width: 100%;
  }

  &.primary {
    height: 100px;

    &.fit {
      width: 315px;
    }
  }

  &.secondary {
    height: 50px;

    &.fit {
      width: 315px;
    }
  }
}

.badge {
  background-color: $alert;
  display: flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  min-width: 20px;
  height: 20px;
  border-radius: 25px;
  overflow: hidden;
  margin: 5px 5px 0 0;
  padding: 0 4px;
}

.badge-container {
  position: absolute;
  padding: 0 0 95px 120px;
}
