@import '../.common-styles//master.scss';

.background {
  display: block;
  width: 100%;
  height: 100%;

  &.full {
    width: 100%;
    height: 100%;
  }

  &.fit {
    position: relative;
    background-color: $dark;
  }
}
