@import '../../visual-components/.common-styles/master.scss';
.container {
  display: block;
  justify-content: center;
  align-content: center;
  align-items: flex-start;
  margin-left: 20px;
  margin-right: 20px;
}

.filterStyle {
  width: 220px;
  word-break: break-all;
}

.filterStyle2 {
  width: 150px;
  display: grid;
  grid-template-columns: 1fr;
  word-break: break-all;
}

.cardIndex {
  width: 1585px;
}

.outerFrame {
  width: 1585px;
  display: grid;
  grid-template-columns: 1fr;
  margin-left: -10px;
}

.wrapper {
  width: 1585px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-top: 10px;
  margin-left: 30px;
}

.chaptertitle {
  width: 1585px;
  height: 40px;
  margin: 30px 20px 0;
  line-height: 0.5em;
  background: #808080;
  font-size: 1.5em;
  font-weight: bold;
  color: white;
  text-align: left;
  padding-top: 16px;
  padding-left: 20px;
}

.group {
  display: grid;
  grid-template-columns: 1fr;
  margin: 20px;
}

.wrapperInv {
  width: 1550px;
  display: grid;
  grid-template-columns: 1fr;
}

.graphInv {
  width: 1550px;
  height: 350px;
  margin-right: 40px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.cardGroupInv {
  margin-top: 20px;
  margin-left: 30px;
}

.cardInv {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 20px;
  margin-bottom: 5px;
}

.cardContentInv {
  width: 280px;
  height: 130px;
  padding-top: 10px;
  padding-left: 27px;
  padding-bottom: 20px;
}

.cardContentSales {
  width: 280px;
  height: 135px;
  padding-top: 15px;
  padding-left: 25px;
  padding-bottom: 55px;
  padding-right: 25px;
}

.cardContentSales2Items {
  width: 280px;
  height: 180px;
  padding-top: 15px;
  padding-left: 40px;
  padding-bottom: 55px;
  padding-right: 50px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 5px;
}

.cardContentSalesBrew {
  width: 280px;
  height: 255px;
  padding-top: 15px;
  padding-left: 40px;
  padding-bottom: 55px;
  padding-right: 50px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 5px;
}

.cardContentSales4RowItems {
  width: 200px;
}

.cardContentSalesLowerRow {
  margin-top: 10px;
}

.cardContentSales2RowItems {
  margin-top: 10px;
  width: 200px;
}

.cardItemValueSalesBrew {
  text-align: right;
  color: #1d6ab7;
  font-size: 1.5em;
}

.cardItemName {
  width: 150px;
  color: black;
  font-size: 0.9em;
  margin-top: 7px;
}

.cardItemName1Item {
  height: 10px;
  color: black;
  text-align: center;
  white-space: nowrap;
  font-size: 0.9em;
  padding-top: 7px;
  margin: 0 auto;
}

.cardItemValueSales {
  text-align: center;
  color: #1d6ab7;
  font-size: 1.5em;
  height: 10px;
  margin-top: 10px;
}

.cardItemTitleInv {
  width: 170px;
  color: black;
  white-space: nowrap;
  text-align: center;
  text-decoration: underline;
  font-size: 0.8em;
  margin-left: -45px;
}

.cardItemTitleSales {
  color: black;
  //white-space: nowrap;
  line-height: 1;
  text-align: center;
  text-decoration: underline;
  font-size: 1.2em;
  margin-bottom: 10px;
}

.cardItemTitleCountryDist {
  color: black;
  white-space: nowrap;
  text-align: center;
  font-size: 1em;
  //font-weight: bold;
  margin-top: -5px;
  margin-bottom: 5px;
}

.cardItemTitleBrand {
  color: black;
  //white-space: nowrap;
  line-height: 1;
  text-align: center;
  text-decoration: underline;
  font-size: 1.2em;
  margin-top: 15px;
  margin-bottom: 5px;
}

.cardContentCountryDist {
  width: 280px;
  height: 180px;
  padding-top: 15px;
  padding-left: 25px;
  padding-bottom: 55px;
  padding-right: 25px;
}

.cardsCountryDistAlign {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;
}

.grouptitle {
  height: 30px;
  line-height: 0.5em;
  background: #808080;
  margin-top: 30px;
  padding-top: 10px;
  font-size: 1.3em;
  font-weight: bold;
  color: white;
  text-align: center;
}

.graphtitle {
  font-size: 1.3rem;
  font-weight: bold;
  margin-left: 30px;
  margin-top: 10px;
  margin-bottom: -10px;
  text-align: left;
}

.graphTop {
  height: 350px;
  margin-bottom: 15px;
}

.graphBottom {
  height: 350px;
  margin-bottom: 40px;
}

.groupAlert {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 30px;
  margin-bottom: 10px;
}

.graphAlert {
  height: 350px;
  margin-bottom: 20px;
  margin-right: 50px;
  margin-left: -20px;
  margin-top: -20px;
}

.graphLocAlert {
  height: 350px;
  margin-bottom: 20px;
  margin-right: 50px;
  margin-left: -20px;
  margin-top: 10px;
}

.graphTopAlert {
  margin-top: -10px;
  margin-bottom: 50px;
}

.graphTopAlertBrew {
  margin-top: -20px;
  margin-bottom: 50px;
}

.graphTopAlertRest {
  height: 140px;
  margin-bottom: 30px;
}

.graphBottomAlert {
  height: 350px;
  margin-bottom: 60px;
  margin-top: -25px;
}

.footerMargin {
  height: 50px;
}

.cardNoteBrew {
  margin-top: 10px;
  margin-left: 40px;
}

.cardNoteBrew p {
  color: #333333;
  font-size: 1em;
}

.cardAlert {
  width: 500px;
  height: 245px;
  padding-left: 60px;
  padding-right: 60px;
  margin-top: -20px;
}

.cardAlertActiveBrew {
  width: 500px;
  height: 550px;
  padding-left: 50px;
  padding-right: 50px;
}

.cardAlertHistBrew {
  width: 500px;
  height: 585px;
  padding-left: 50px;
  padding-right: 50px;
}

.cardAlertHistDist {
  width: 500px;
  height: 320px;
  padding-left: 60px;
  padding-right: 60px;
  margin-top: -20px;
}

.cardAlertRest {
  width: 500px;
  height: 140px;
  padding-left: 60px;
  padding-right: 60px;
  margin-top: -20px;
}

.cardAlertDividerTop {
  margin-top: 0px;
  margin-bottom: 10px;
}

.cardAlertDividerBottom {
  margin-top: 35px;
  margin-bottom: 10px;
}

.cardTitle {
  font-weight: normal;
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 0px;
}

.cardTitleBrew {
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.cardTitleRest {
  font-weight: normal;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.cardItemValue {
  text-align: center;
  color: #1d6ab7;
  font-size: 1.4em;
  margin-top: 15px;
}

.cardItemValueUnprocessable {
  text-align: center;
  color: #ec0c0c;
  font-size: 1.4em;
  margin-top: 15px;
}

.cardItemTitle {
  color: black;
  text-align: center;
  font-size: 0.9em;
  line-height: 0.9;
  margin-top: 5px;
  margin-bottom: -20px;
  white-space: pre-wrap;
}

.cardItemValueRest {
  color: #1d6ab7;
  font-size: 1.4em;
  margin-top: 10px;
  text-align: center;
}

.cardItemValueRestUnprocessable {
  color: #ec0c0c;
  font-size: 1.4em;
  margin-top: 10px;
  text-align: center;
}

.cardItemTitleRest {
  color: black;
  text-align: center;
  font-size: 0.9em;
  line-height: 0.9;
  margin-bottom: -20px;
  text-align: center;
  white-space: pre-wrap;
}

.cardItemCategory {
  color: black;
  text-decoration: underline;
  text-align: left;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: -25px;
  margin-left: 10px;
  font-size: 1.2em;
}

.tableRootMini {
  width: 500px;
}

.alertTable {
  margin: 23px auto;
  margin-left: 27px;
}

.tableRow {
  height: 5;
}

.tableHyperLink {
  color: #ec0c0c;
  text-decoration: underline;
}

.bulbIcon {
  padding-top: 8px;
}

.description {
  margin-left: 30px;
  margin-top: 10px;
}

.description h5 {
  font-weight: normal;
  font-size: 1.1em;
  color: black;
}

.description h4 {
  font-weight: normal;
  font-size: 1.1em;
  color: red;
}

.mobileDescription {
  width: 315px;
  color: gray;
  white-space: nowrap;
  text-align: left;
  font-size: 0.6em;
}

.cardDivider {
  width: 1570px;
  margin: 25px 0 10px 30px;
  border-top: 1px dotted #827a7a;
}

@media (max-width: 1919px) {
  .container {
    margin-left: 20px;
    margin-right: 20px;
  }

  .cardIndex {
    width: 1050px;
  }

  .outerFrame {
    width: 1050px;
    margin-left: -5px;
  }

  .wrapper {
    width: 1050px;
    margin-left: 20px;
  }

  .wrapperAlert {
    width: 1050px;
    display: grid;
    margin-left: 0px;
  }

  .chaptertitle {
    width: 1050px;
    margin: 20px 0 5px;
    margin-left: 10px;
  }

  .description {
    margin-left: 10px;
    margin-bottom: 5px;
    padding: 0 10px 0;
  }

  .tableRootMini {
    width: 330px;
  }

  .tableRoot {
    width: 1000px;
  }

  .graphTopAlert {
    margin-bottom: 40px;
  }

  .graphTopAlertBrew {
    margin-bottom: -120px;
  }

  .graphTopAlertRest {
    margin-bottom: 15px;
  }

  .graphBottomAlert {
    width: 300px;
    padding-right: 0px;
    margin-bottom: 60px;
  }

  .graphAlert {
    height: 330px;
    margin-right: 40px;
  }

  .graphLocAlert {
    height: 365px;
    margin-right: 40px;
  }

  .cardNoteBrew {
    margin-top: 5px;
    margin-left: 30px;
    margin-bottom: 5px;
    font-size: 0.8em;
  }

  .cardAlert {
    width: 330px;
    height: 220px;
    padding-right: 35px;
    padding-left: 35px;
  }

  .cardAlertBrew {
    width: 330px;
    height: 250px;
    padding-right: 50px;
    padding-left: 50px;
    box-shadow: 10px 10px 4px;
  }

  .cardAlertActiveBrew {
    width: 330px;
    height: 550px;
    padding-right: 50px;
    padding-left: 50px;
    margin-top: -15px;
    margin-bottom: 165px;
    box-shadow: 10px 10px 4px;
  }

  .cardAlertHistBrew {
    width: 330px;
    height: 670px;
    padding-right: 50px;
    padding-left: 50px;
    margin-top: -15px;
    margin-bottom: 160px;
  }

  .cardAlertHistDist {
    width: 330px;
    height: 300px;
    padding-right: 30px;
    padding-left: 30px;
    margin-top: -15px;
  }

  .cardAlertRest {
    width: 330px;
    height: 135px;
    padding-right: 50px;
    padding-left: 50px;
  }

  .cardTitle {
    font-size: 1.1em;
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 0px;
  }

  .cardTitleBrew {
    font-size: 1em;
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 0px;
  }

  .cardTitleRest {
    font-size: 1em;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  .cardItemUser {
    margin-left: 0px;
  }

  .cardItemTitle {
    font-size: 0.9em;
    line-height: 0.9;
  }

  .cardItemCategory {
    font-size: 1.2em;
    font-weight: normal;
    margin-left: 0px;
  }

  .alertTable {
    margin: 23px 0;
    margin-left: 30px;
  }

  .wrapperInv {
    width: 1000px;
  }

  .graphtitle {
    margin-left: 20px;
  }

  .cardGroupInv {
    margin-left: 20px;
  }

  .cardInv {
    grid-template-columns: repeat(3, 1fr);
  }

  .graphInv {
    width: 1050px;
  }

  .tableRoot {
    width: 1000px;
  }

  .cardContentSales {
    width: 330px;
  }

  .cardContentSales2Items {
    width: 330px;
  }

  .cardContentSalesBrew {
    width: 330px;
  }

  .cardContentSales4RowItems {
    width: 250px;
  }

  .cardContentSales2RowItems {
    width: 250px;
  }

  .cardContentCountryDist {
    width: 330px;
  }

  .cardItemValueRest {
    //margin-top: 0px;
    margin-left: 10px;
  }

  .cardItemTitleRest {
    font-size: 0.8em;
    margin-top: 5px;
    margin-left: 10px;
  }

  .description h5 {
    font-size: 0.9em;
  }

  .cardDivider {
    width: 1030px;
    margin: 25px 0 10px 20px;
  }
}

@media (max-width: 1024px) {
  .container {
    margin-left: auto;
    margin-right: auto;
  }
  .filterStyle {
    width: 680px;
  }

  .filterStyle2 {
    width: 600px;
  }

  .cardIndex {
    width: 740px;
  }

  .outerFrame {
    width: 740px;
    margin-left: 20px;
  }

  .wrapper {
    width: 740px;
    grid-template-columns: repeat(2, 1fr);
    margin-left: 0px;
  }

  .wrapperAlert {
    width: 740px;
    margin-left: 0px;
  }

  .chaptertitle {
    width: 700px;
    margin-left: 0px;
  }

  .description {
    width: 700px;
    margin-left: 0px;
  }

  .graphtitle {
    margin-left: 5px;
  }

  .cardNoteBrew {
    width: 680px;
    margin-left: 20px;
  }

  .cardAlert {
    width: 330px;
    height: 210px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .cardItemCategory {
    margin-top: 10px;
  }

  .cardAlertActiveBrew {
    height: 550px;
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 140px;
  }

  .cardAlertHistBrew {
    height: 540px;
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 135px;
  }

  .cardAlertHistDist {
    height: 285px;
    padding-left: 25px;
    padding-right: 25px;
  }

  .cardAlertRest {
    //width: 330px;
    //height: 140px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .cardItemUser {
    margin-left: 5px;
  }

  .cardTitle {
    font-size: 1.1em;
    padding-left: 0px;
    margin-top: 10px;
    margin-bottom: 0;
  }

  .cardTitleBrew {
    font-size: 1.1em;
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 5px;
  }

  .graphTopAlert {
    width: 330px;
    margin-bottom: 40px;
  }

  .graphTopAlertBrew {
    margin-bottom: -95px;
  }

  .graphBottomAlert {
    margin-bottom: 60px;
  }

  .tableRoot {
    width: 700px;
  }

  .alertTable {
    margin: 23px 0;
  }

  .group {
    margin-left: 5px;
    margin-right: 5px;
  }

  .graphTop {
    height: 275px;
    margin-bottom: 15px;
  }

  .graphBottom {
    height: 275px;
    margin-bottom: 40px;
  }

  .wrapperInv {
    width: 700px;
  }

  .cardInv {
    grid-template-columns: repeat(2, 1fr);
  }

  .cardContentInv {
    width: 320px;
    height: 120px;
  }

  .cardContentSales {
    width: 320px;
    //height: 120px;
  }

  .cardContentSales2Items {
    width: 320px;
  }

  .cardContentSalesBrew {
    width: 320px;
  }

  .cardContentCountryDist {
    width: 320px;
  }

  .cardContentSales4RowItems {
    width: 230px;
  }

  .cardContentSalesLowerRow {
    margin-top: 5px;
  }

  .cardContentSales2RowItems {
    margin-top: 10px;
    width: 230px;
  }

  .cardGroupInv {
    margin-left: 5px;
  }

  .graphInv {
    width: 700px;
    margin-left: -20px;
  }

  .cardDivider {
    width: 690px;
    margin: 25px 0 10px 5px;
  }
}

@media (max-width: 575px) {
  .cardIndex {
    width: 353px;
  }

  .filterStyle {
    width: 300px;
  }

  .filterStyle2 {
    width: 200px;
  }

  .outerFrame {
    width: 315px;
    margin-left: 20px;
  }

  .wrapper {
    width: 315px;
    grid-template-columns: 1fr;
    margin-left: auto;
    margin-right: auto;
  }

  .wrapperAlert {
    margin-left: auto;
    margin-right: auto;
  }

  .groupAlert {
    width: 315px;
  }

  .chaptertitle {
    width: 315px;
    margin: 20px 0 5px;
  }

  .graphtitle {
    width: 315px;
    font-size: 1rem;
    margin-left: 0px;
  }

  .description {
    width: 315px;
    margin-left: -10px;
    margin-bottom: 0px;
  }

  .description h5 {
    width: 310px;
    word-wrap: break-word;
  }

  .cardNoteBrew {
    margin-left: 10px;
    width: 290px;
  }

  .cardAlert {
    width: 315px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .cardAlertActiveBrew {
    width: 315px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .cardAlertHistBrew {
    width: 315px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .cardAlertHistDist {
    width: 315px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .cardAlertRest {
    width: 315px;
    //height: 140px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .cardTitle {
    font-size: 1.1em;
    margin-left: 0px;
    margin-top: 5px;
    margin-bottom: 0;
  }

  .cardTitleBrew {
    font-size: 1.1em;
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .cardItemUser {
    margin-left: 0px;
  }

  .graphAlert {
    width: 350px;
    height: 300px;
    margin-left: -30px;
  }

  .graphLocAlert {
    width: 350px;
    height: 300px;
    margin-left: -30px;
  }

  .graphTopAlert {
    width: 315px;
    margin-bottom: 40px;
  }

  .graphTopAlertBrew {
    width: 315px;
    margin-bottom: -100px;
  }

  .graphBottomAlert {
    margin-bottom: 65px;
  }

  .alertTable {
    margin: 23px 0;
  }

  .tableRootMini {
    width: 315px;
  }

  .tableRoot {
    width: 315px;
  }

  .group {
    margin: 0px;
  }
  .grouptitle {
    font-size: 1.2em;
  }

  .graphTop {
    height: 260px;
    margin-bottom: 15px;
  }

  .graphBottom {
    height: 260px;
    margin-bottom: 40px;
  }

  .wrapperInv {
    width: 330px;
  }

  .graphInv {
    width: 350px;
    margin-right: 0px;
    margin-left: -40px;
  }

  .cardGroupInv {
    margin-left: 7px;
    margin-bottom: 15px;
  }

  .cardInv {
    grid-template-columns: 1fr;
  }

  .cardContentInv {
    width: 300px;
    height: 140px;
  }

  .cardContentSales {
    width: 300px;
  }

  .cardContentSales2Items {
    width: 300px;
    height: 170px;
  }

  .cardContentSalesBrew {
    width: 300px;
  }

  .cardContentCountryDist {
    width: 300px;
  }

  .cardContentSalesLowerRow {
    margin-top: 5px;
  }

  .cardContentSales4RowItems {
    width: 210px;
  }

  .cardContentSales2RowItems {
    margin-top: 5px;
    width: 210px;
  }

  .cardDivider {
    width: 310px;
    margin: 15px 0 10px 0px;
  }
}
