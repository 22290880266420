@import '../.common-styles/master.scss';

.date-picker > div {
  font-family: $main-font-family;
  height: 44px;
  border: 1px solid $dark;
  border-radius: 25px;
  padding: 8px;
  font-size: 14px;
  color: $secondary-dark2;
  line-height: 44px;
}
