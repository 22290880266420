@import '../.common-styles/master.scss';

.nested-table-container {
  padding: 5px;
}

.row {
  border-bottom: $dashed-border;

  &:last-child {
    border-bottom: none;
  }
}

.cell {
  padding: 5px;
  background-color: $dark;

  &.header-cell {
    background-color: unset;
  }
}
