@import '../../visual-components/.common-styles/master.scss';

.header {
  background-color: $dark4;
  height: 68px;
  display: block;
  position: fixed;
  width: 100%;
  z-index: 1250;
  box-shadow: 0 0 10px $black;
}
