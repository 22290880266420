.restaurant-table {
  width: 100%;
  padding: 20px;
  border-collapse: separate;
}

.table-header {
  display: table-header-group;

  .header-cell {
    height: 30px;
  }

  .header-row {
    height: 30px;
  }
}
