$main-font-family: 'Noto Sans JP', sans-serif;
$main-font-color: #fff;

$anim-transition-time: 100ms;

/* Primary */
$primary: #dbb26e;
$primary-dark1: #d4a453;
$primary-dark2: #cd9538;
$primary-light1: #e2c089;
$primary-light2: #e9cfa4;
$primary-light3: #efddc0;
$primary-light4: #f6ecdb;
$primary-bg: #f9f3f8;

/* Secondary */
$secondary: #81c7d4;
$secondary-dark1: #68bccb;
$secondary-dark2: #4eb0c3;
$secondary-light1: #9ad2dd;
$secondary-light2: #b4dee5;
$secondary-light3: #cde9ee;
$secondary-light4: #e6f4f7;
$secondary-bg: #f3fafb;

/* Alert */
$alert: #dd2a4f;
$alert-bg: #fcf2f2;

/* Darks */
$dark: #0f2540;
$dark0: #2f75ca;
$dark1: #2965ae;
$dark2: #225593;
$dark3: #1c4577;
$dark4: #15355c;

$black: #000;
$white: #fff;

$status1: #e04224;
$status2: #d9aa01;
$status3: #0ca789;
$status4: #999;

$dashed-border: dashed 1px $dark0;

.colored-background {
  background-color: $dark;
}
