@import '../.common-styles/master.scss';

$dashed-border: dashed 1px $dark0;

.detail {
  padding: 30px 30px 26px 30px;
  border-left: $dashed-border;
  display: inline-table;
  margin-left: 20px;

  &-empty {
    display: table-row;
    height: 30px;
  }

  &-row {
    display: table-row;
  }

  &-cell {
    display: table-cell;
    padding-bottom: 4px;
  }
}
