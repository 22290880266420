@import '../.common-styles/master.scss';

.notification-register {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
}

.page-title {
  padding-bottom: 30px;
}

.title {
  padding-bottom: 20px;
  width: 100%;
}

.content {
  padding-bottom: 25px;
  width: 100%;
}
