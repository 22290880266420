@import '../.common-styles/master.scss';

.table-row {
  background-color: $dark4;
}

.table-cell {
  padding: 10px;
  border-top: $dashed-border;

  &.first-row {
    border-top: none;
    &:first-child {
      border-top-left-radius: 10px;
    }

    &:last-child {
      border-top-right-radius: 10px;
    }
  }

  &.last-row {
    &:first-child {
      border-bottom-left-radius: 10px;
    }

    &:last-child {
      border-bottom-right-radius: 10px;
    }
  }
}

.flagship {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 25px;
  border-radius: 25px;
  background-color: $dark2;

  &.is-flagship {
    background-color: $primary;
  }
}

.selling-info-collapse-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 25px;
  border: 1px solid;
  border-color: $white;
  border-radius: 25px;
  cursor: pointer;

  &.is-collapsed {
    border-color: $dark2;
  }
}

.padded-right {
  padding-right: 5px;
}
