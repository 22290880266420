@import '../.common-styles/master.scss';

.footer {
  background-color: $dark4;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  bottom: 0;
}

.contents {
  width: 100%;
  padding: 0 0 5px 30px;
  justify-content: flex-start;
}

.privacy-policy-link {
  width: 100%;
  padding-top: 20px;
}

.copyright {
  padding-left: 30px;
  width: 100%;
  text-overflow: ellipsis;
}
