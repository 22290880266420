@import '../.common-styles/master.scss';

.text-display {
  font-family: $main-font-family;

  &.truncate-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.size {
  &-xx-small {
    font-size: 8px;
  }

  &-x-small {
    font-size: 10px;
  }

  &-small {
    font-size: 12px;
  }

  &-medium {
    font-size: 14px;
  }

  &-large {
    font-size: 16px;
  }

  &-x-large {
    font-size: 20px;
  }
}

.weight {
  &-bold {
    font-weight: bold;
  }

  &-default {
    font-weight: normal;
  }
}

.color {
  &-default {
    color: $main-font-color;
  }

  &-black {
    color: $black;
  }

  &-primary {
    color: $primary;
  }

  &-secondary {
    color: $secondary;
  }

  &-secondary-light2 {
    color: $secondary-light2;
  }

  &-dark {
    color: $dark;
  }

  &-dark0 {
    color: $dark0;
  }

  &-dark2 {
    color: $dark2;
  }

  &-alert {
    color: $alert;
  }
}

.display {
  &-inline {
    display: inline-block;
  }

  &-block {
    display: block;
  }

  &-flex-centered-text {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.align {
  &-center {
    text-align: center;
  }

  &-end {
    text-align: end;
  }

  &-default {
    text-align: initial;
  }
}

.link {
  text-decoration: underline;
  cursor: pointer;
}
