@import '../.common-styles/master.scss';

.map-marker {
  width: 100px;
  display: flex;
}

.icon-bg {
  text-align: center;
}

.text-bg {
  background-color: $dark2;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
