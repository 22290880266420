@import '../.common-styles/master.scss';

.notification-detail-popup {
  background-color: $white;
  max-width: 90vw;
  max-height: 90vh;
  word-wrap: break-word;
  overflow-wrap: break-word;
  padding: 20px;
}

.title {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  max-height: 400px;
  overflow-y: scroll;
}

.button-container {
  padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.button {
  padding-bottom: 10px;

  &:last-child {
    padding-bottom: 0;
  }
}

.white {
  color: white;
}
.red {
  //background-color: red;
  color: #ff0000;
}
.blue {
  //background-color: blue;
  color: #15355c;
}

.rcorners {
  border-radius: 10px;
  border: 1px solid #050505;
  padding: 5px;
  width: auto;
  height: auto;
  margin: 5px;
}
