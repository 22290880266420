@import '../.common-styles/master.scss';

.serial-location-edit-popup {
  width: 310px;
  padding: 20px;
}

.title {
  padding-bottom: 20px;
}

.contents-container {
  padding-bottom: 20px;
}

.element {
  padding-bottom: 10px;
  &:last-child {
    padding-bottom: 0;
  }
}

.button-container {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
}

.button {
  padding-bottom: 10px;
  &:last-child {
    padding-bottom: 0;
  }
}

.date-picker > div {
  font-family: $main-font-family;
  height: 44px;
  border: 1px solid $dark;
  border-radius: 25px;
  padding: 8px;
  font-size: 14px;
  color: $secondary-dark2;
  line-height: 44px;
}
