@import '../.common-styles/master.scss';

.button {
  height: 44px;
  min-width: 275px;
  border-radius: 25px;
}

.theme {
  &-primary {
    background-color: $primary-dark2;

    &:hover {
      filter: brightness(120%);
    }
  }

  &-secondary {
    background-color: $secondary-light4;

    &:hover {
      filter: brightness(95%);
    }
  }
}

.custom-color {
  &-status1 {
    background-color: $status1;
  }

  &-status2 {
    background-color: $status2;
  }

  &-status3 {
    background-color: $status3;
  }

  &-status4 {
    background-color: $status4;
  }

  &-dark0 {
    background-color: $dark0;
  }

  &-secondary-light4 {
    background-color: $secondary-light4;
  }
}

.size {
  &-full {
    width: 100%;
  }
  &-fit {
    width: auto;
  }
  &-round {
    width: 80px;
    height: 80px;
    min-width: 50px;
    border-radius: 40px;
    box-shadow: 1px 1px 7px;
  }
}

.display {
  &-inline {
    display: inline-block;
  }
  &-block {
    display: block;
  }
}
