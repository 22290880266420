@import '../.common-styles/master.scss';

.update-history-popup {
  max-width: 420px;
  background-color: $white;
  padding: 20px;
}

.update-history {
  padding: 10px 0;
  border-bottom: $dashed-border;

  &:last-child {
    border-bottom: none;
  }

  &:first-child {
    padding-top: 0;
  }

  &-content {
    padding-bottom: 10px;
  }
}

.field {
  padding-right: 10px;

  &:last-child {
    padding-right: 0;
  }
}
