@import '../.common-styles/master.scss';

.distribution-detail-image-container {
  background-color: $white;
  height: 120px;
  // width: 30%;
  display: flex;
  align-items: end;
  justify-content: center;
  margin-top: 40px;
}

.distribution-detail-image {
  max-width: 100%;
  max-height: 120px;
}

.alert-status-pill {
  position: absolute;
  top: 80px;
  left: 10px;
  height: 25px;
}

.brand-bottle {
  position: absolute;
  top: 80px;
  left: 10px;
}
