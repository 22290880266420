@import '../.common-styles/master.scss';

.date-range-picker {
  display: flex;
  align-items: center;
  flex-flow: wrap;

  &.full > :global(.rs-picker-daterange) {
    display: block;
    width: 100%;
  }

  &.fit > :global(.rs-picker-daterange) {
    display: inline-block;
  }

  :global(.rs-picker-toggle .rs-picker-toggle-placeholder) {
    color: $secondary-dark2 !important;
    font-size: 14px;
  }

  :global(.rs-picker-daterange .rs-picker-toggle) {
    height: 44px;
    display: inline-flex;
    align-items: center;
    border-radius: 25px;
    border: 1px solid $dark;
  }

  :global(.rs-picker-daterange .rs-picker-toggle-value) {
    color: $secondary-dark2;
    font-family: $main-font-family;
    font-size: 14px;
  }

  :global(.rs-picker-toggle-caret:before) {
    color: $secondary-dark2;
  }

  :global(.rs-picker-toggle-clean) {
    color: $secondary-dark2;
    top: auto !important;
  }

  :global(.rs-picker-toggle-caret) {
    top: auto !important;
  }
}
