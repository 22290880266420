.floating-area {
  display: inline-block;
  position: fixed;
}

.location {
  &-bottom-right {
    right: 20px;
    bottom: 50px;
  }

  &-bottom-left {
    left: 20px;
    bottom: 180px;
  }
}
