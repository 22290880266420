@import '../.common-styles/master.scss';

.card {
  background-color: $dark4;
  border-radius: 15px;
  padding: 0 5px;

  &-alert {
    &-unprocessed {
      background-color: $status1;
    }

    &-processing {
      background-color: $status2;
    }

    &-processed {
      background-color: $status3;
    }

    &-unprocessable {
      background-color: $status4;
    }

    &-approved {
      background-color: $dark0;
    }
  }

  &.has-content {
    padding-bottom: 5px;
  }
}

.origin-name {
  padding: 14px 5px 14px;
  display: flex;
  justify-content: space-between;
}

.content {
  background-color: $dark;
  border-radius: 10px;
  width: 100%;

  &-row {
    border-bottom: $dashed-border;
    margin: 0 5px;
    padding: 10px;

    &-flex {
      display: flex;
      justify-content: space-between;
    }

    &:last-child {
      border: none;
    }
  }
}

.alert-message {
  background-color: $alert;
  border-radius: 2px;
  padding: 1px 5px;
}

.alert-message-container {
  padding-top: 5px;
  display: inline-block;
}
