@import '../.common-styles/master.scss';

.filter-anchor {
  background-color: $dark;
  width: 100%;
  text-align: center;
  padding: 15px;
}

.search-icon {
  padding-right: 10px;
  height: 25px;
}

.contents {
  background-color: $dark3;
  display: inline-flex;
  cursor: pointer;
  border-radius: 25px;
  padding: 5px;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
}
