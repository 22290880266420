@import '../.common-styles/master.scss';

.restaurant-image-slider {
  width: 100%;
  max-height: 400px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.image {
  max-width: 300px;
  max-height: 300px;
}

.image-container {
  width: 450px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-button {
  background-color: $primary;
  padding: 10px;
  border-radius: 25px;
  cursor: pointer;
}

.label {
  padding-top: 10px;
}
