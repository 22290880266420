@import '../.common-styles/master.scss';

.brand-popup {
  max-width: 420px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
}

.brand-image-container {
  width: 200px;
  height: auto;
  min-height: 200px;
  background-color: $white;
}

.brand-image {
  width: 200px;
  height: auto;
}

.details {
  background-color: $secondary-bg;
  border-radius: 6px;
  width: 100%;
  min-width: 300px;
}

.row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: $dashed-border;
  padding: 0 10px;

  .cell {
    line-height: 2;
  }

  &:last-child {
    border: none;
  }
}
