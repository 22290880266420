@import '../.common-styles/master.scss';

.alert-status-pill {
  border-radius: 25px;
  display: flex;
  align-items: center;

  &.pill {
    min-width: 50px;
    height: 22px;
    justify-content: center;

    &.unselected {
      background-color: $white;
    }
  }

  &.big-pill {
    min-width: 75px;
    height: 33px;
    justify-content: center;

    &.unselected {
      background-color: $white;
    }
  }

  &.toggle {
    height: 22px;
    justify-content: space-between;
    padding: 0 10px;

    &.unselected {
      background-color: $dark;
      border: 1px solid $dark2;
    }
  }

  &.unprocessed {
    background-color: $status1;
  }

  &.processing {
    background-color: $status2;
  }

  &.processed {
    background-color: $status3;
  }

  &.unprocessable {
    background-color: $status4;
  }

  &.approved {
    background-color: $dark0;
  }

  &.clickable {
    cursor: pointer;
  }
}
