@import '../.common-styles/master.scss';

.input {
  font-family: $main-font-family;

  border: solid 1px $dark;
  font-size: 14px;
  outline: none;
  appearance: none;
  border-radius: 25px;
  padding: 8px;
  box-sizing: border-box;

  &.primary {
    color: $secondary-dark2;
    background-color: $white;
  }

  &.secondary {
    color: $white;
    background-color: $dark3;
  }

  &.text {
    height: 44px;
    line-height: 44px;
  }

  &.textarea {
    height: 150px;
    line-height: 1.6;
  }

  &::placeholder {
    color: $secondary-dark2;
  }

  &.fit {
    display: inline-block;
  }

  &.full {
    width: 100%;
  }
}
